import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import Search from '../assets/images/search.png';
import Axios from 'axios';
import heading from '../assets/images/heading.png';
import '../App.css';
import back from '../assets/images/back.png';
import { useNavigate } from "react-router-dom";


export default () => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [searchResult, setSearchResult] = useState("");
    const [searchButtonHide, setSearchButtonHide] = useState(false);
    const [isEmptyResult, setIsEmptyResult] = useState(false);
    const [doctorsDetails, setDoctorsDetails] = useState([]);

    const location = useLocation();
    // console.log(location.state);
    const navigate = useNavigate();

    const phoneNumberChangeHandler = (e) => {
        setPhoneNumber(e.target.value);

    }

    const searchHandler = () => {
        setSearchButtonHide(true);
        setIsEmptyResult(false);

        Axios.get(`${global.baseUrl}/api/patientapi/MyDoctors/get_details?searchResult=${searchResult}`).then((response) => {
            console.log("doctors details ", response.data);
            if (response.data.status == '1') {
                if (response.data.responselength > 0) {
                    setSearchButtonHide(false);
                    setDoctorsDetails(response.data.response);
                } else {
                    setSearchButtonHide(false);
                    setIsEmptyResult(true);
                    setDoctorsDetails([])
                }
            }

        });
    }

    return (
        <div>
            <div >
                <img src={heading} style={{ height: 80, width: '100%' }} />
            </div>
            <div style={{ textAlign: 'left' }}>
                <img src={back} style={{ height: 20, width: 20, marginTop: 20, marginLeft: 20 }} onClick={() => navigate("/ConsultationHistoryList")} />
            </div>

            <p style={{ marginTop: 20, fontSize: 18, fontWeight: 'bold', color: '#A696FF' }}>My Doctors</p>
            <div style={{ marginTop: 30, display: 'flex', flexDirection: 'row', marginLeft: 10, marginRight: 20 }}>

                <input style={{ border: '1px solid rgb(185, 184, 184)', width: '45%', height: 30, paddingLeft: 8, marginLeft: 10, fontSize: 12, borderRadius: 7, textAlign: 'left' }} type="number" placeholder="WhatsApp Number "
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={(event) => phoneNumberChangeHandler(event)}
                />
                <div style={{ marginLeft: 10 }}>
                    <div className="view-details"  >
                        <div style={{ marginTop: 5 }}>
                            <a style={{ textDecoration: 'none', color: 'white' }} href={`https://api/patientapi.whatsapp.com/send?phone=${phoneNumber}&text=emr.connectedbytes.in/ConsultationHistoryDetails?id=${location.state}`}>Share with doctors </a>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 50, marginLeft: 10 }}>

                <input type="text" required className="inputstyle" style={{ width: '70%', height: 30, fontSize: 12, paddingLeft: 5, marginLeft: 10 }} value={searchResult} placeholder="Search doctors by phonenumber or firstname"
                    onChange={(e) => {
                        setSearchResult(e.target.value);
                    }}

                />
                {
                    searchButtonHide == false ?
                        <>
                            <div className="searchBtn" onClick={searchHandler} >
                                <img style={{ height: 15, width: 15, marginTop: 7 }} src={Search} />
                            </div>
                        </>
                        :
                        <p style={{ fontSize: 12, color: 'green', fontWeight: 'bold', marginLeft: 5 }}>Searching...</p>

                }
            </div>
            {
                isEmptyResult ?
                    <p style={{ marginTop: 15, fontSize: 13, fontWeight: 'bold', color: 'red' }}>No Doctors found!</p>
                    :
                    null
            }

            {

                doctorsDetails.map((result) => {
                    return (
                        <div style={{ marginTop: 25 }}>
                            <div className="doctors-card" key={result.doctorsid}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ width: '25%', }}>

                                        <img src={result.profile_image} style={{ height: 70, width: 70, marginTop: 5, borderRadius: 40 }} />

                                    </div>
                                    <div style={{ width: '75%', textAlign: 'left', marginLeft: 10 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <p style={{ fontSize: 17, fontWeight: '600' }}>{result.first_name} {result.last_name}</p>

                                        </div>
                                        <p style={{ fontSize: 14, marginTop: -8 }}>{result.degree}</p>
                                        <p style={{ fontSize: 14, marginTop: -8 }}>{result.specialization}</p>
                                        {/* <p style={{ fontSize: 14, marginTop: 4 }}>{result.experience}</p> */}

                                    </div>
                                </div>

                            </div>
                        </div>
                    )
                })
            }
        </div>


    )
}