import React, { useState, useEffect } from 'react';
import $ from "jquery";
import { useLocation } from 'react-router';
import { useNavigate } from "react-router-dom";
import back from '../assets/images/back.png';
import axios from 'axios';

export default (props) => {

    const [fhistoryDisease1, setFHistoryDisease1] = useState("");
    const [fhistoryRelation1, setFHistoryRelation1] = useState("");
    const [fhistoryDisease2, setFHistoryDisease2] = useState("");
    const [fhistoryRelation2, setFHistoryRelation2] = useState("");
    const [buttonHide, setButtonHide] = useState(false);
    const [msg, setMsg] = useState("");
    const [seesionId, setSessionId] = useState(Number);
    const [relation, setRelation] = useState([]);
    const [familyHistoryDisease, setFamilyHistoryDisease] = useState([]);

    const location = useLocation();
    console.log(location.state)

    const navigate = useNavigate();



    useEffect(() => {

        let id = localStorage.getItem("patientid");
        console.log(id);
        setSessionId(id)

        if (location.state.family_history_1_disease != "") {
            setFHistoryDisease1(location.state.family_history_1_disease)
            setFHistoryDisease2(location.state.family_history_2_disease)
            setFHistoryRelation1(location.state.family_history_1_relation)
            setFHistoryRelation2(location.state.family_history_2_relation)
        }

        axios.get(`${global.baseUrl}/api/patientapi/PickList/get_family_ralation`).then((response) => {
            console.log(response.data);
            if (response.data.status == '1') {
                setRelation(response.data.response);
            }
        });
        axios.get(`${global.baseUrl}/api/patientapi/PickList/get_family_disease`).then((response) => {
            console.log(response.data);
            if (response.data.status == '1') {
                setFamilyHistoryDisease(response.data.response);
            }
        });

    }, []);




    const disease1ChangeHandler = (e) => {
        setFHistoryDisease1(e.target.value);
        console.log(e.target.value);

    }
    const disease2ChangeHandler = (e) => {
        setFHistoryDisease2(e.target.value);

    }
    const relation1ChangeHandler = (e) => {
        setFHistoryRelation1(e.target.value);

    }
    const relation2ChangeHandler = (e) => {
        setFHistoryRelation2(e.target.value);

    }

    const saveHandler = (e) => {

        setButtonHide(true);
        e.preventDefault();
        let formdata = $(e.target);
        formdata = new FormData();

        formdata.append('patient_id', seesionId);
        formdata.append('family_history_1_disease', fhistoryDisease1);
        formdata.append('family_history_1_relation', fhistoryRelation1);
        formdata.append('family_history_2_disease', fhistoryDisease2);
        formdata.append('family_history_2_relation', fhistoryRelation2);

        $.ajax({
            type: "POST",
            url: `${global.baseUrl}/api/patientapi/PatientHealthProfile/update_family_history_by_patientid`,
            data: formdata,
            processData: false,
            contentType: false,
            success(data) {
                console.log(data);
                navigate("/PatientHealthProfile")


            },
            error: function (err) {
                console.log(err);
                alert("Update failed  ", err);
                setButtonHide(false);

            }
        });



    }



    return (
        <>
            <div style={{ textAlign: 'left' }}>

                <img src={back} style={{ height: 20, width: 20, marginTop: 20, marginLeft: 20 }} onClick={() => navigate("/PatientHealthProfile")} />
            </div>
            <p style={{ fontSize: 20, fontWeight: 'bold', color: '#A696FF', marginTop: -10 }}>Edit Family History</p>



            <div className='first-div'>
                <div style={{ display: 'flex', flexDirection: 'row', height: 'auto' }}>
                    <table id='prescriptiondata' style={{ width: 'auto', paddingRight: 5, borderRadius: 5, marginLeft: '7%', marginRight: '5%' }}>
                        <thead>
                            <tr>
                                <th style={{ width: "50%", textAlign: 'center', fontWeight: 'normal', fontSize: 13 }}>Disease</th>
                                <th style={{ width: "40%", textAlign: 'center', fontWeight: 'normal', fontSize: 13, marginRight: 10 }}>Relation</th>


                            </tr>
                        </thead>
                        <tbody>

                            <td style={{ textAlign: 'left' }} className="value">
                                <div className="select-wrapper">
                                    <select
                                        name="fhistoryDisease1"
                                        value={fhistoryDisease1}
                                        onChange={(event) => disease1ChangeHandler(event)}
                                        className="input-bordered"
                                        style={{ fontSize: 13, fontWeight: 'bold', width: '100%' }}

                                    >
                                        <option value="">Select</option>
                                        {
                                            familyHistoryDisease.map((val) => {
                                                return (

                                                    <option value={val.code}>{val.value}</option>

                                                )
                                            })

                                        }
                                    </select>
                                </div>
                            </td>
                            <td style={{ textAlign: 'left' }} className="value">
                                <div className="select-wrapper">
                                    <select
                                        name="fhistoryRelation1"
                                        value={fhistoryRelation1}
                                        onChange={(event) => relation1ChangeHandler(event)}
                                        className="input-bordered"
                                        style={{ fontSize: 13, fontWeight: 'bold', width: '100%' }}

                                    >
                                        <option value="">Select</option>
                                        {
                                            relation.map((val) => {
                                                return (
                                                    <option value={val.code}>{val.value}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </td>
                        </tbody>
                        <tbody>
                            <td style={{ textAlign: 'left' }} className="value">
                                <div className="select-wrapper">
                                    <select
                                        name="fhistoryDisease2"
                                        value={fhistoryDisease2}
                                        onChange={(event) => disease2ChangeHandler(event)}

                                        className="input-bordered"
                                        style={{ fontSize: 13, fontWeight: 'bold', width: '100%' }}

                                    >
                                        <option value="">Select</option>
                                        {
                                            familyHistoryDisease.map((val) => {
                                                return (

                                                    <option value={val.code}>{val.value}</option>

                                                )
                                            })

                                        }
                                    </select>
                                </div>
                            </td>
                            <td style={{ textAlign: 'left' }} className="value">
                                <div className="select-wrapper">
                                    <select
                                        name="fhistoryRelation2"
                                        value={fhistoryRelation2}
                                        onChange={(event) => relation2ChangeHandler(event)}

                                        className="input-bordered"
                                        style={{ fontSize: 13, fontWeight: 'bold', width: '100%' }}

                                    >
                                        <option value="">Select</option>
                                        {
                                            relation.map((val) => {
                                                return (

                                                    <option value={val.code}>{val.value}</option>

                                                )
                                            })

                                        }
                                    </select>
                                </div>
                            </td>
                        </tbody>




                    </table>

                    {/* <div style={{ width: '50%' }}>
                        <p className="label" style={{ marginTop: 10 }}>Disease:&nbsp;</p>
                    </div> */}

                    {/* <div className="select-wrapper">
                        <select
                            name="fhistoryDisease1"
                            value={fhistoryDisease1}
                            onChange={(event) => disease1ChangeHandler(event)}
                            className="input-bordered"
                            style={{ fontSize: 13, fontWeight: 'bold', width: 100 }}

                        >
                            {
                                familyHistoryDisease.map((val) => {
                                    return (

                                        <option >{val.disease}</option>

                                    )
                                })

                            }
                        </select>
                    </div> */}

                    {/* <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 20 }}>
                        <div style={{ width: '50%' }}>
                            <p className="label" style={{ marginTop: 10 }} >Relation:&nbsp;</p>
                        </div>
                        <div className="select-wrapper">
                            <select
                                name="fhistoryRelation1"
                                value={fhistoryRelation1}
                                onChange={(event) => relation1ChangeHandler(event)}
                                className="input-bordered"
                                style={{ fontSize: 13, fontWeight: 'bold', width: 100 }}

                            >
                                {
                                    relation.map((val) => {
                                        return (
                                            <option >{val.value}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div> */}
                </div>

                {/* <div style={{ display: 'flex', flexDirection: 'row', marginTop: 15 }}>
                    <div style={{ width: '50%' }}>
                        <p className="label" style={{ marginTop: 10 }}>Disease:&nbsp;</p>
                    </div>



                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 20 }}>
                        <div style={{ width: '50%' }}>
                            <p className="label" style={{ marginTop: 10 }} >Relation:&nbsp;</p>
                        </div>


                    </div>
                </div> */}
                <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                    <form method="post"
                        onSubmit={(event) => saveHandler(event)}>
                        {
                            buttonHide == false ?
                                <button type="submit" className="btn btn-auto btn-success">
                                    Update
                                </button>
                                :
                                <p style={{ fontSize: 14, fontWeight: 'bold', color: 'green' }}>Updating...</p>
                        }
                    </form>
                </div>
                <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>

                    {msg != "" ? <p style={{ marginTop: 20, fontSize: 13, fontWeight: 'bold', color: 'green', marginLeft: 'auto', marginRight: 'auto' }}>{msg}</p> : null}
                </div>
            </div>


        </>
    )
}