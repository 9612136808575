import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import back from '../assets/images/back.png';
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import editicon from '../assets/images/editicon.png';
import Axios from 'axios';
import { useRecoilState, useRecoilValue } from 'recoil';
import { is_patient_profile_data_updated, patient_profile_image } from '../Store/PatientProfileDetails'
import user from '../assets/images/user.png';

export default () => {
    const [name, setName] = useState("");
    const [dob, setDob] = useState("");
    const [age, setAge] = useState("");
    const [email, setEmail] = useState("");
    const [gender, setGender] = useState("");
    const [bloodGroup, setBloodGroup] = useState("A+");
    const [buttonHide, setButtonHide] = useState(false);
    const [buttonHideForEdit, setButtonHideForEdit] = useState(false);
    const [seesionId, setSessionId] = useState(Number);
    const profileImg = useRecoilValue(patient_profile_image);
    const [msg, setMsg] = useState("");
    const [editBox, setEditbox] = useState(false);
    const location = useLocation();
    console.log(location.state);
    const navigate = useNavigate();
    let phoneno = localStorage.getItem("phoneNo");
    let profileData = JSON.parse(localStorage.getItem("patientLocalData"));
    const [isProfileUpdated, setIsProfileUpdated] = useRecoilState(is_patient_profile_data_updated);
    const moment = require('moment');

    useEffect(() => {
        let id = localStorage.getItem("patientid");
        console.log("patient data :", profileData);
        setSessionId(id);

        setName(profileData.first_name);
        if (profileData.dob || profileData.dob != null) {
            setDob(profileData.dob);
        }
        else {
            setDob("");
        }
        if (profileData.gender || profileData.gender != null) {
            setGender(profileData.gender);
        }
        else {
            setGender("");
        }
        if (profileData.email || profileData.email != null) {
            setEmail(profileData.email);
        }
        else {
            setEmail("");
        }
        if (profileData.blood_group || profileData.blood_group != null) {
            setBloodGroup(profileData.blood_group);
        }
        else {
            setBloodGroup("");
        }
        if (profileData.age || profileData.age != null) {
            setAge(profileData.age);
        }
        else {
            setAge("");
        }

        // Axios.get(`${global.baseUrl}/api/commonapi/File_reader/read_file_content_patient?patientid=${location.state.up_id}&file=${location.state.profile_image}`)
        //     .then((response) => {
        //         console.log("buffer file = ", response.data.base64String);
        //         setProfileImg(response.data.base64String);

        //     });
    }, []);


    let bloodgroupList = [
        {
            id: 1,
            key: 'A+',
            value: 'A+',

        },
        {
            id: 2,
            key: 'A-',
            value: 'A-',

        },
        {
            id: 3,
            key: 'B+',
            value: 'B+',

        },
        {
            id: 4,
            key: 'B-',
            value: 'B-',

        },
        {
            id: 5,
            key: 'O+',
            value: 'O+',

        },
        {
            id: 6,
            key: 'O-',
            value: 'O-',

        },
        {
            id: 7,
            key: 'AB+',
            value: 'AB+',

        },
        {
            id: 8,
            key: 'AB-',
            value: 'AB-',

        },
    ]

    const handleChangeName = (event) => {

        setName(event.target.value);
    }
    const handleChangeDob = (event) => {
        if (event.target.value || event.target.value != null) {
            setDob(event.target.value);
        }
        else {
            setDob("");
        }

    }
    const handleChangeEmail = (event) => {

        setEmail(event.target.value);
    }

    const handleChangeBloodGroup = (event) => {

        setBloodGroup(event.target.value);
    }

    const onGenderUpdate = (e) => {
        setGender(e.target.value);
    }
    const editHandler = () => {
        setEditbox(true);
        setButtonHideForEdit(true);
    }
    const ageHandler = (e) => {
        setAge(e.target.value);
    }


    const updateHandler = (e) => {
        if (age != "" && gender != "" && bloodGroup != "") {
            setButtonHide(true);

            e.preventDefault();
            let formdata = $(e.target);
            formdata = new FormData();
            formdata.append('id', seesionId);
            formdata.append('name', name);
            formdata.append('dob', dob);
            formdata.append('email', email);
            formdata.append('gender', gender);
            formdata.append('bloodGroup', bloodGroup);
            formdata.append('age', age);
            let bioData = profileData;
            console.log("age :", bioData.age);

            $.ajax({
                type: "POST",
                url: `${global.baseUrl}/api/patientapi/PatientBioProfile/update_profile`,
                data: formdata,
                processData: false,
                contentType: false,
                success(data) {
                    console.log("updated successfully", data);
                    setButtonHide(false);
                    setEditbox(false);
                    setButtonHideForEdit(false);
                    setIsProfileUpdated(true);

                },
                error: function (err) {
                    console.log(err.responseText);
                    alert(" Update Failed ! error has occured  ", err.responseText);
                }
            });
        } else {
            e.preventDefault();
            setMsg("all * fields are mandatory");
        }

    }



    return (
        <div>
            <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                <img src={back} style={{ height: 20, width: 20, marginTop: 20, marginLeft: 20 }} onClick={() => navigate("/")} />

            </div>
            <p style={{ fontSize: 20, fontWeight: 'bold', color: '#A696FF', marginTop: -10 }}>Bio Profile</p>
            <div style={{ marginTop: 20 }}>

                {profileImg ?
                    <img
                        style={{ height: 90, width: 90, borderRadius: '50%' }}
                        src={profileImg}
                    />
                    :
                    <img
                        style={{ height: 90, width: 90, borderRadius: '50%' }}
                        src={user}
                    />
                }
                {profileImg ?
                    <><div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '35%' }}></div>
                        <p className="label_style" style={{ fontSize: 15, marginTop: 5, width: '25%' }}>Edit photo</p>
                        <img src={editicon} style={{ height: 15, width: 15, marginLeft: 5, marginTop: 12 }} onClick={() => navigate("/EditPhoto", { state: location.state })} />
                    </div>
                    </>
                    :
                    <><div style={{ display: 'flex', flexDirection: 'row' }}>
                        <p className="label_style" style={{ fontSize: 15, marginTop: 5, marginLeft: 125 }}>Add photo</p>
                        <img src={editicon} style={{ height: 15, width: 15, marginLeft: 5, marginTop: 12 }} onClick={() => navigate("/EditPhoto", { state: location.state })} />
                    </div>
                    </>
                }
            </div>
            {
                editBox == false ?

                    <div className='first-div'>



                        <div style={{ marginTop: 15 }} className="flex-div">
                            <div style={{ width: '35%', textAlign: 'left' }}>

                                <p className="label_style" > Name:</p>
                            </div>
                            <div style={{ width: '65%' }}>
                                <p style={{ fontSize: 15, fontWeight: '400', marginTop: 4, textAlign: 'left' }}>{name} </p>

                            </div>

                        </div>
                        <div style={{ marginTop: 15 }} className="flex-div">
                            <div style={{ width: '35%', textAlign: 'left' }}>

                                <p className="label_style" > Phone No:</p>
                            </div>
                            <div style={{ width: '65%' }}>
                                <p style={{ fontSize: 15, fontWeight: '400', marginTop: 4, textAlign: 'left' }}>{phoneno} </p>

                            </div>

                        </div>
                        <div style={{ marginTop: 15 }} className="flex-div">
                            <div style={{ width: '35%', textAlign: 'left' }}>

                                <p className="label_style" > Email:</p>
                            </div>
                            <div style={{ width: '65%' }}>
                                <p style={{ fontSize: 15, fontWeight: '400', marginTop: 4, textAlign: 'left' }}>{email} </p>

                            </div>

                        </div>
                        <div style={{ marginTop: 15 }} className="flex-div">
                            <div style={{ width: '35%', textAlign: 'left' }}>

                                <p className="label_style" >Age: </p>
                            </div>
                            <div style={{ width: '65%' }}>
                                <p style={{ fontSize: 15, fontWeight: '400', marginTop: 4, textAlign: 'left' }}>{age == "0" ? "" : age} </p>

                            </div>

                        </div>
                        <div style={{ marginTop: 15 }} className="flex-div">
                            <div style={{ width: '35%', textAlign: 'left' }}>

                                <p className="label_style" > DOB: </p>
                            </div>
                            <div style={{ width: '65%' }}>
                                <p style={{ fontSize: 15, fontWeight: '400', marginTop: 4, textAlign: 'left' }}>{dob ? moment(dob).format('DD.MM.YYYY') : ""} </p>

                            </div>

                        </div>
                        <div style={{ marginTop: 15 }} className="flex-div">
                            <div style={{ width: '35%', textAlign: 'left' }}>

                                <p className="label_style" > Gender: </p>
                            </div>
                            <div style={{ width: '65%' }}>
                                <p style={{ fontSize: 15, fontWeight: '400', marginTop: 4, textAlign: 'left' }}>{gender} </p>

                            </div>

                        </div>
                        <div style={{ marginTop: 15 }} className="flex-div">
                            <div style={{ width: '35%', textAlign: 'left' }}>

                                <p className="label_style" > Blood Group: </p>
                            </div>
                            <div style={{ width: '55%' }}>
                                <p style={{ fontSize: 15, fontWeight: '400', marginTop: 4, textAlign: 'left' }}>{bloodGroup} </p>

                            </div>

                        </div>
                        {
                            gender ?
                                buttonHideForEdit == false ?
                                    <button style={{ marginTop: 30 }} className="btn btn-auto btn-primary" onClick={editHandler}>
                                        Edit Bio Details
                                    </button> : null
                                :
                                buttonHideForEdit == false ?
                                    <button style={{ marginTop: 30 }} className="btn btn-auto btn-primary" onClick={editHandler}>
                                        Add Bio Details
                                    </button> : null

                        }



                    </div>
                    : null
            }
            {
                editBox ?
                    <>


                        <div className='first-div'>

                            <div style={{ marginTop: 15 }} className="flex-div">
                                <div style={{ width: '35%', textAlign: 'left' }}>

                                    <p className="label_style" > Name:</p>
                                </div>
                                <div style={{ width: '65%' }}>

                                    <input
                                        className="input_style"
                                        type="text"
                                        name="name"
                                        value={name}
                                        onChange={(event) => handleChangeName(event)}
                                        maxLength={30}
                                    />
                                </div>

                            </div>
                            <div style={{ marginTop: 15 }} className="flex-div">
                                <div style={{ width: '35%', textAlign: 'left' }}>

                                    <p className="label_style" > Gender: *</p>
                                </div>
                                <div style={{ width: '65%' }}>

                                    <select
                                        className="input_style"
                                        name="gender"
                                        value={gender}
                                        onChange={(event) => onGenderUpdate(event)}
                                        style={{ fontSize: 13, height: 25 }}
                                    >

                                        <option value="" >select</option>
                                        <option  >Male</option>
                                        <option  >Female</option>
                                        <option  >others</option>


                                    </select>
                                </div>

                            </div>
                            <div style={{ marginTop: 15 }} className="flex-div">
                                <div style={{ width: '35%', textAlign: 'left' }}>

                                    <p className="label_style" > Age: *</p>
                                </div>
                                <div style={{ width: '65%' }}>

                                    <input
                                        className="input_style"
                                        type="tel"
                                        name="age"
                                        value={age == "0" ? "" : age}
                                        onChange={(event) => ageHandler(event)}
                                        maxLength={3}

                                    />
                                </div>

                            </div>
                            <div style={{ marginTop: 20 }} className="flex-div">
                                <div style={{ width: '35%', textAlign: 'left' }}>

                                    <p className="label_style" > DOB:</p>
                                </div>
                                <div style={{ width: '65%' }}>

                                    <input
                                        className="input_style"
                                        type="date"
                                        name="dob"
                                        value={dob}
                                        onChange={(event) => handleChangeDob(event)}
                                    />
                                </div>
                            </div>
                            <div style={{ marginTop: 20 }} className="flex-div">
                                <div style={{ width: '35%', textAlign: 'left' }}>

                                    <p className="label_style" > Email:</p>
                                </div>
                                <div style={{ width: '65%' }}>

                                    <input
                                        className="input_style"
                                        type="email"
                                        name="email"
                                        value={email}
                                        onChange={(event) => handleChangeEmail(event)}
                                        maxLength={30}
                                    />
                                </div>
                            </div>
                            <div style={{ marginTop: 20 }} className="flex-div">
                                <div style={{ width: '38%', textAlign: 'left' }}>

                                    <p className="label_style"  > Blood Group: *</p>
                                </div>
                                <div style={{ width: '62%' }}>

                                    <div style={{ width: '95%' }} className="select-wrapper">
                                        <select

                                            name="bloodGroup"
                                            value={bloodGroup}
                                            onChange={(event) => handleChangeBloodGroup(event)}

                                            className="input-bordered"
                                            style={{ fontSize: 13, fontWeight: 'bold', height: 25 }}

                                        > <option value="">select</option>
                                            {
                                                bloodgroupList.map((val) => {
                                                    return (

                                                        <option >{val.value}</option>

                                                    )
                                                })

                                            }
                                        </select>
                                    </div>


                                </div>
                            </div>
                            <p style={{ color: 'red', fontSize: 16, textAlign: 'left', marginLeft: 65, fontWeight: 'bold', marginTop: 10 }}>{msg}</p>

                            <form method="post"
                                onSubmit={(event) => updateHandler(event)}
                            >
                                {
                                    buttonHide == false ?
                                        <button type="submit" style={{ marginTop: 30 }} className="btn btn-auto btn-primary">
                                            Update
                                        </button>
                                        :
                                        <p style={{ fontSize: 14, color: 'green', fontWeight: 'bold', marginTop: 20 }}>Updating...</p>

                                }


                            </form>


                        </div>
                    </>
                    : null
            }

        </div>
    )

}