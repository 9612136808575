import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router';
import back from '../assets/images/back.png'
import { useNavigate } from "react-router-dom";
import plus from '../assets/images/plus.png';
import '../App.css';
import camera from '../assets/images/camera.png';
import Webcam from 'react-webcam';
import cameraSwitch from '../assets/images/cameraSwitch.png';
import select from '../assets/images/select.jpg';
import cross from '../assets/images/cross.png';
import $ from "jquery";
import Axios from 'axios';
import documents from '../assets/images/documents.png';
import deleteicon from '../assets/images/delete-icon.jpg';
import folder from '../assets/images/folder.png';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Collapsible from 'react-collapsible';
import { BiFolderPlus } from "react-icons/bi";
import imageCompression from 'browser-image-compression';
import pdf from '../assets/images/pdf.png';

export default () => {
    const navigate = useNavigate();
    const [addRecordButtonHide, setAddRecordButtonHide] = useState(false);
    const [openCameraButton, setOpenCameraButton] = useState(false);
    const [cameraOpen, setCameraOpen] = useState(false);
    const [capturedImage, setCapturedImage] = useState("");
    const [base64ImageToFile, setBase64ImageToFile] = useState("");
    const [file_type, setFile_type] = useState("");
    const [isCapturePhotoHide, setIsCapturePhotoHide] = useState(false);
    const [isOpenDocumentCategory, setIsOpenDocumentCategory] = useState(false);
    const [reportsCategory, setReportsCategory] = useState("");
    const [document_name, setDocument_name] = useState("");
    const [isButtonHide, setIsButtonHide] = useState(false);
    const [uploadedDocuments, setUploadedDocuments] = useState([]);
    const [isEmptyDocumentName, setIsEmptyDocumentName] = useState(false);
    const [noRecords, setNoRecords] = useState(false);
    const [isDeleteDoc, setIsDeleteDoc] = useState(false);
    const [uploadedDocId, setUploadedDocId] = useState("");
    const [refreshUploadedDoc, setRefreshUploadedDoc] = useState(false);
    const [uploadedFolder, setUploadedFolder] = useState([]);
    const [uploadedFile, setUploadedFile] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isNoFile, setIsNoFile] = useState(false);
    const [isOpenCollapse, setIsOpenCollapse] = useState(false);
    const [isInputSectionOpen, setIsInputSectionOpen] = useState(false);
    const [backButtonHide, setBackButtonHide] = useState(false);
    const [doctorsName, setDoctorsName] = useState("");
    const [visitingPurpose, setVisitingPurpose] = useState("");
    const [seesionId, setSeesionId] = useState(Number);
    const [msg, setMsg] = useState("");
    const [imageURL, setImageURL] = useState(null);
    const [myFile, setMyFile] = useState(null);
    const [selectFile, setSelectFile] = useState(false);
    const [showSelectFile, setShowSelectFile] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    let patientUniqueId = localStorage.getItem("patientUniqueId");
    console.log(patientUniqueId);
    let compressedFile;
    let url;


    let pid;

    const location = useLocation()


    const FACING_MODE_USER = "user";
    const FACING_MODE_ENVIRONMENT = "environment";

    const videoConstraints = {
        facingMode: FACING_MODE_ENVIRONMENT
    };

    const [facingMode, setFacingMode] = useState(FACING_MODE_ENVIRONMENT);
    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const handleClick = useCallback(() => {
        setFacingMode(
            prevState =>
                prevState === FACING_MODE_ENVIRONMENT
                    ? FACING_MODE_USER
                    : FACING_MODE_ENVIRONMENT
        );
    }, []);

    const backHandler = () => {
        global.OTPValidation = 'true';
        navigate("/ViewMedicalRecors", { state: { id: location.state } });
    }
    // const handleClickOpen = () => {
    //     setOpen(true);
    // };
    const webcamRef = React.useRef(null);

    const startCam = () => {
        setShowSelectFile(false);
        setAddRecordButtonHide(true);
        console.log("camera is opening");
        setCameraOpen(true);
        setIsCapturePhotoHide(false);
        setRefreshUploadedDoc(false);





    }

    const addRecordsHandler = () => {
        setAddRecordButtonHide(true);
        setOpenCameraButton(true);
    }
    const backToRecordsHandler = () => {
        setIsInputSectionOpen(false);
        setBackButtonHide(true);
        setAddRecordButtonHide(false);
        setOpenCameraButton(false);
        setCameraOpen(false);
        setCapturedImage("");
        setIsOpenDocumentCategory(false);
        setShowSelectFile(false);
        setErrMsg("");
    }
    const selectedPhotoHandler = () => {
        setIsOpenDocumentCategory(true);

    }

    const reportsCategoryChangeHandler = (e) => {
        setReportsCategory(e.target.value);
        if (e.target.value == "Prescription") {
            setDocument_name("Prescription");
        }

    }

    const capturePhoto = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            // console.log("captured image ", imageSrc);
            setCapturedImage(imageSrc);
            setIsCapturePhotoHide(true);
            setErrMsg("");
            console.log("captured photo  ", imageSrc);
            const url = imageSrc;
            fetch(url)
                .then(res => res.blob())
                .then(blob => {
                    const file = new File([blob], "Filename.jpeg", { type: "image/jpeg" })
                    console.log(file);
                    setBase64ImageToFile(file);
                    setFile_type("image/jpeg");
                })


        },
        [webcamRef]
    );
    const documentNameChangeHandler = (e) => {
        setDocument_name(e.target.value);


    }

    useEffect(() => {

        pid = localStorage.getItem("patientid");
        console.log(pid);
        setSeesionId(pid);


    }, [])
    const fileInputRef = useRef();
    const triggerUpload = () => {
        fileInputRef.current.click()
    }

    const fileChangeHandler = async (event) => {
        setAddRecordButtonHide(true);
        setShowSelectFile(false);
        setSelectFile(true);
        setErrMsg("");
        console.log("fileChangeHandler: selectedfile  ", event.target.files[0])
        setFile_type(event.target.files[0].type);


        const options1 = {
            maxSizeMB: 0.2,
            maxWidthOrHeight: 1000,
            useWebWorker: true,
        };




        try {
            if (event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/png") {
                if (event.target.files[0].size > 204800) {
                    compressedFile = await imageCompression(event.target.files[0], options1);
                    console.log("compressed file ", compressedFile)
                    const file = new File([compressedFile], `${compressedFile.name}.jpeg`, { type: "image/jpeg" })
                    setMyFile(file);
                    url = URL.createObjectURL(compressedFile)
                    console.log("url", url)
                    setImageURL(url)
                    setShowSelectFile(true);
                    setIsOpenDocumentCategory(true);

                }
                else {
                    setMyFile(event.target.files[0]);
                    url = URL.createObjectURL(event.target.files[0])
                    console.log("url", url)
                    setImageURL(url)
                    setShowSelectFile(true);
                    setIsOpenDocumentCategory(true);
                }
            }
            else if (event.target.files[0].type == "application/pdf") {
                if (event.target.files[0].size > 614400) {
                    setErrMsg("Max 600kb pdf size is allowed");
                }
                else {
                    setMyFile(event.target.files[0]);
                    url = URL.createObjectURL(event.target.files[0])
                    console.log("url", url)
                    setImageURL(url)
                    setShowSelectFile(true);
                    setIsOpenDocumentCategory(true);
                }

            }

            else {
                setErrMsg("Unsupported file type, only .pdf, .jpg, .png files are allowed");

            }
        }
        catch (error) {
            console.log(error);
        }
    }


    const handleDeleteClose = () => {
        setDeleteOpen(false);
    };


    const uploadRecords = (e) => {
        console.log(seesionId)
        if (document_name != "") {
            setIsButtonHide(true);
            e.preventDefault();
            // let formdata = $(e.target);
            let formdata = new FormData();
            if (base64ImageToFile == '') {
                formdata.append('file', myFile);
            }
            else {
                formdata.append('file', base64ImageToFile);
            }

            formdata.append('patientid', patientUniqueId);
            $.ajax({
                type: "POST",
                url: `${global.baseUrl}/api/commonapi/FileUpload/insert`,
                data: formdata,
                processData: false,
                contentType: false,
                success(data) {
                    console.log("savehandler:file upload success response:  ", data);


                    if (data) {
                        const filedata = new FormData();
                        filedata.append("file", `${data}`);
                        filedata.append('patients_id_fk', seesionId);
                        filedata.append('category', reportsCategory);
                        filedata.append('document_name', document_name);
                        filedata.append('document_type', file_type);
                        filedata.append('patients_doc_folder_id', location.state);

                        $.ajax({
                            type: "POST",
                            url: `${global.baseUrl}/api/patientapi/PatientMedicalRecord/insert`,
                            data: filedata,
                            processData: false,
                            contentType: false,
                            success(data) {
                                console.log("upload recordshandler:records upload success response:  ", data);
                                setMsg("Record added successfully");
                                navigate("/ViewMedicalRecors", { state: { id: location.state } })
                                setIsButtonHide(false);
                                setRefreshUploadedDoc(true);

                            },
                            error: function (err) {
                                console.log(err.responseText);
                                alert("error has occured  ", err);
                            }
                        });

                    }
                },
                error: function (err) {
                    console.log(err.responseText);
                    alert("error has occured  ", err);
                }
            });
        }
        else {
            e.preventDefault();
            console.log("documnt is empty")
            setIsEmptyDocumentName(true);
        }

    }



    return (
        <>
            <div style={{ textAlign: 'left' }}>

                <img src={back} style={{ height: 20, width: 20, marginTop: 15, marginLeft: 20 }} onClick={backHandler} />
            </div>

            <p style={{ fontSize: 20, fontWeight: 'bold', color: '#A696FF', marginTop: -10 }}>Add Records</p>
            <div style={{ height: '100%', width: '100%' }}>
                {
                    msg != "" ?
                        <p style={{ marginTop: 20, fontSize: 14, fontWeight: 'bold', color: 'green' }}> {msg}</p>
                        :
                        null
                }
                {
                    errMsg != "" ?
                        <p style={{ marginTop: 20, fontSize: 14, fontWeight: 'bold', color: 'red', textAlign: 'left', paddingLeft: 20, paddingRight: 20 }}> {errMsg}</p>
                        :
                        null
                }


                <div style={{ height: '65%', width: '100%' }}>


                    {
                        cameraOpen == true && capturedImage == "" ?


                            <Webcam

                                // videoConstraints={videoConstraints}
                                style={{ width: 300, height: 300 }}
                                // screenshotFormat="image/jpeg"
                                // audio={false}
                                ref={webcamRef}

                                audio={false}
                                screenshotFormat="image/jpeg"
                                videoConstraints={{
                                    ...videoConstraints,
                                    facingMode
                                }}

                            /> : null
                    }

                    {
                        capturedImage != "" ?
                            <>
                                <img name="file" src={capturedImage} style={{ marginTop: "10%", height: 280, width: 370 }} />

                            </>
                            :
                            null

                    }
                    {
                        showSelectFile ?

                            file_type != "application/pdf" ?
                                imageURL && <img src={imageURL} style={{ height: 100, width: 100, textAlign: 'left', marginTop: 30 }} />
                                : <img src={pdf} style={{ height: 50, width: 50, textAlign: 'left', marginTop: 30 }} />

                            : null
                    }



                </div >
                <div style={{ height: '35%', width: '100%', marginTop: 15 }}>
                    {/* {
                        cameraOpen == true && isCapturePhotoHide == false ?
                            <div style={{ marginTop: 10 }}>

                                <button onClick={handleClick} className="capture-photo-button" > <img src={cameraSwitch} style={{ height: 18, width: 18, paddingRight: 3 }} />Switch Camera</button>
                            </div>
                            : null


                    } */}
                    {
                        cameraOpen ?
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
                                {
                                    isCapturePhotoHide == false ?




                                        <>
                                            <button onClick={backToRecordsHandler} style={{ marginLeft: 30 }} className="capture-photo-button" > <img src={back} style={{ height: 15, width: 15 }} /> Back </button>
                                            <button onClick={capturePhoto} className="capture-photo-button" > <img src={camera} style={{ height: 18, width: 18 }} />&nbsp;Capture Photo</button>

                                            <button onClick={handleClick} style={{ marginRight: 30 }} className="capture-photo-button" > <img src={cameraSwitch} style={{ height: 18, width: 18, paddingRight: 3 }} />Switch Camera</button>

                                        </>
                                        :

                                        <>
                                            {
                                                isOpenDocumentCategory == false ?
                                                    <>
                                                        <button style={{ marginLeft: 65 }} className="capture-photo-button" onClick={selectedPhotoHandler}  > <img src={select} style={{ height: 21, width: 21 }} /> Select Photo </button>
                                                        <button style={{ marginRight: 95 }} className="capture-photo-button" onClick={backToRecordsHandler} > <img src={cross} style={{ height: 12, width: 12 }} /> Cancel</button>
                                                    </>
                                                    :
                                                    null

                                            }

                                        </>
                                }



                            </div>
                            :
                            null




                    }

                    {
                        isOpenDocumentCategory == true ?
                            <>
                                <p style={{ fontSize: 16, fontWeight: 'bold', marginLeft: -85 }}>Select a document category</p>
                                <div className="select-wrapper" style={{ width: '80%', backgroundColor: 'white', marginLeft: 30, marginTop: 10 }} >
                                    <select style={{ width: '80%', fontSize: 16, marginLeft: -40 }}
                                        name="reportsCategory"
                                        value={reportsCategory}
                                        onChange={(event) => reportsCategoryChangeHandler(event)}

                                    >
                                        <option value=" "> Select</option>
                                        <option value="Lab Reports"> Lab reports</option>
                                        <option value="Prescription">Prescription</option>

                                    </select>
                                </div>

                                {
                                    reportsCategory == "Lab Reports" || reportsCategory == "" ?
                                        <div >
                                            <p style={{ marginTop: 6, fontSize: 16, fontWeight: 'bold', marginLeft: -125 }}>Select a document name</p>
                                            <div className="select-wrapper" style={{ width: '80%', backgroundColor: 'white', marginLeft: 30, marginTop: 10 }}>
                                                <select style={{ width: '80%', fontSize: 16, backgroundColor: 'white', marginLeft: -40 }}
                                                    name="document_name"
                                                    value={document_name}
                                                    onChange={(event) => documentNameChangeHandler(event)}

                                                >
                                                    <option value=" "> Select</option>
                                                    <option value="Malaria Report"> Malaria Report</option>
                                                    <option value="Tetanus Report">Tetanus Report</option>
                                                    <option value="Typhoid Fever Report">Typhoid Fever Report</option>
                                                    <option value="Common Cold Report">Common Cold Report</option>
                                                    <option value="Heart Attack Report">Heart Attack Report</option>
                                                    <option value="Others Report">Others Report</option>
                                                </select>
                                            </div>
                                            <div style={{ marginLeft: 20, marginTop: -5 }}>
                                                {
                                                    isButtonHide == false ?


                                                        <form method="post"
                                                            onSubmit={(event) => uploadRecords(event)}
                                                        >

                                                            <button type="submit" className="btn btn-success" style={{ marginTop: 30, marginLeft: -40 }}>Upload </button>

                                                        </form>
                                                        :
                                                        <p style={{ marginTop: 10, color: 'green', fontSize: 14, fontWeight: 'bold' }}>Uploading ...</p>

                                                }
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <div style={{ marginLeft: 20, marginTop: 10 }}>
                                                {
                                                    isButtonHide == false ?


                                                        <form method="post"
                                                            onSubmit={(event) => uploadRecords(event)}
                                                        >

                                                            <button type="submit" className="btn btn-success" style={{ marginTop: 30 }}>Upload </button>

                                                        </form>
                                                        :
                                                        <p style={{ color: 'green', fontSize: 14, fontWeight: 'bold' }}>Uploading ...</p>

                                                }
                                            </div>
                                        </div>


                                }

                            </>
                            :
                            null
                    }

                    <div style={{ display: 'flex', marginTop: -15, flexDirection: 'row', justifyContent: 'space-between' }}>

                        {
                            addRecordButtonHide == false ?
                                <> <div className='first-div' style={{ width: 350, height: 310 }}>

                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ textAlign: 'left', paddingLeft: 20 }}>
                                            <p style={{ marginLeft: -8, fontSize: 16, marginTop: 12, fontWeight: '500', color: 'rgb(34, 189, 250)' }} > &nbsp;&nbsp;Step 1</p>
                                            <p style={{ fontSize: 16, height: 24, color: 'black' }} > Click on file if you have record in phone or use camera to take picture of the record.</p>


                                        </div>


                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 25 }}>
                                        <div style={{ textAlign: 'left', paddingLeft: 20 }}>
                                            <p style={{ marginLeft: -8, fontSize: 16, marginTop: 35, fontWeight: '500', color: 'rgb(34, 189, 250)' }} > &nbsp;&nbsp;Step 2</p>
                                            <p style={{ fontSize: 16, height: 60, color: 'black' }} >Entry for each document will be listed there, click on the card to view the file.  </p>

                                            <p style={{ fontSize: 16, height: 60, color: 'black', fontWeight: 'bold' }} > Supported file types are .jpg, .png, .pdf and file size should be maximum 200kb. </p>
                                        </div>


                                    </div>

                                </div>

                                    {/* <div className="add-records" onClick={addRecordsHandler}  >
                                        <img src={plus} style={{ height: 22, width: 30, marginTop: 20, textAlign: 'center' }} />
                                        <p style={{ fontSize: 12, fontWeight: '600', marginTop: 20, width: 80, textAlign: 'left', marginLeft: -5 }}>Add Records</p>

                                    </div> */}
                                </>
                                :
                                null
                        }




                    </div>
                    <div div style={{ display: 'flex', marginTop: -453, marginRight: -94, flexDirection: 'row', justifyContent: 'space-between' }}>



                        {
                            cameraOpen == false ?
                                <>{
                                    backButtonHide == true ?
                                        <div onClick={backHandler} className="back" >

                                            <img src={back} style={{ height: 25, width: 22, marginTop: 20, marginLeft: 3 }} />
                                            <p style={{ fontSize: 13, fontWeight: '600', marginTop: 16, }}>Back</p>
                                        </div> :
                                        <div onClick={backToRecordsHandler} className="back" >

                                            <img src={back} style={{ height: 25, width: 22, marginTop: 20, marginLeft: 3 }} />
                                            <p style={{ fontSize: 13, fontWeight: '600', marginTop: 16, }}>Back</p>
                                        </div>
                                }
                                    {/* <div onClick={backToRecordsHandler} className="back" >

                                        <img src={back} style={{ height: 25, width: 22, marginTop: 20, marginLeft: 3 }} />
                                        <p style={{ fontSize: 13, fontWeight: '600', marginTop: 16, }}>Back</p>
                                    </div> */}
                                    <div className="select-device">
                                        <input className="fileinputstyle" style={{ marginTop: 15 }} type="file" name="mfile" id="mfile" ref={fileInputRef} onChange={(event) => fileChangeHandler(event)} />

                                        <button style={{ marginTop: -15, height: 60, marginLeft: -90, width: 60 }} onClick={triggerUpload} class="camera"><BiFolderPlus size={30} /></button>

                                        <p style={{ fontSize: 13, fontWeight: '600', marginTop: 3, marginLeft: -115 }}>File</p>
                                    </div>
                                    <div className="camera" onClick={startCam} style={{ marginleft: 85 }}  >
                                        <img src={camera} style={{ height: 30, width: 30, marginTop: 15 }} />
                                        <p style={{ fontSize: 13, fontWeight: '600', marginTop: 16, }}>Camera</p>
                                    </div>
                                </>
                                : null
                        }


                    </div>

                </div>

            </div >
        </>
    )
}