import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import axios from 'axios';

export default () => {
    const [img, setImg] = useState("");

    let filename;
    let patientid;
    let fileType;
    const searchid = useLocation().search;
    filename = new URLSearchParams(searchid).get('file');
    patientid = new URLSearchParams(searchid).get('patientid');
    fileType = new URLSearchParams(searchid).get('fileType');
    console.log("File Reader: Filename= ", filename);

    console.log("File Reader: File Type= ", fileType);

    useEffect(() => {



        axios.get(`${global.baseUrl}/api/commonapi/File_reader/read_file_content_patient?patientid=${patientid}&file=${filename}`).then((response) => {
            console.log("buffer file = ", response.data.base64String);
            setImg(response.data.base64String);
            // const binaryData =  response.data;
            // const buffer = new Buffer(binaryData);
            //  base64String = buffer.toString('base64');
            // console.log("base64string  ",base64String)
        });

    }, []);

    return (
        <>
            <div>
                {
                    fileType == "application/pdf" ?
                        <>
                            <div>
                                <object width="100%" height="1100" data={img} type="application/pdf"> </object>
                            </div>

                        </>
                        :
                        <img style={{ height: 'auto', width: 'auto', marginTop: 10 }} src={img} />
                }
            </div>

        </>
    )
}