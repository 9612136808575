import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import folder from '../assets/images/ticket1.png';
import back from '../assets/images/back.png';
import plus from '../assets/images/plus.png';
import Axios from 'axios';
import Spinner from '../components/Spinner';
const moment = require('moment');

const Tickets = () => {
    let phoneno = localStorage.getItem("phoneNo");
    const [tickets, setTickets] = useState([]);
    const [msg, setmsg] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const backHandler = () => {
        global.OTPValidation = 'true';
        navigate("/");
    }
    const addTicketHandler = () => {
        navigate("/AddTicket");

    }
    useEffect(() => {
        setmsg("");
        Axios.get(`${global.baseUrl}/api/patientapi/PatientsSupport/get_tickets_by_phoneno?phone_no=${phoneno}`).then((response) => {
            console.log(response.data.response)
            setLoading(false);
            if (response.data.status == '1') {

                if (response.data.response.length > 0) {
                    setTickets(response.data.response);
                }
                else {
                    setmsg("No Issues found. Click on Add Ticket Button for any issues");
                }
            }
        });
    }, [])
    return (
        <div>
            {
                loading ? <Spinner /> :
                    <>
                        <div style={{ textAlign: 'left' }}>

                            <img src={back} style={{ height: 20, width: 20, marginTop: 15, marginLeft: 20 }} onClick={backHandler} />
                        </div>

                        <p style={{ fontSize: 20, fontWeight: 'bold', color: '#A696FF', marginTop: -10 }}>Support Issues</p>
                        <p style={{ fontSize: 15, fontWeight: 'bold', color: 'black' }}>We are committed to solve your problem</p>
                        <div style={{ height: '100%', width: '100%' }}>
                            {msg ? <p style={{ fontSize: 14, fontWeight: 'bold', color: 'red', marginTop: 20 }}>{msg}</p> : null}
                            {
                                tickets.map((details) => {
                                    return (
                                        <><div className="medical-records-cards" onClick={() => navigate("/ViewTicket", { state: details })}>
                                            <div key={details.id} style={{ display: 'flex', flexDirection: 'row', marginTop: 5, marginLeft: 10, }} >
                                                <div style={{ width: 320 }}>
                                                    <div style={{ textAlign: 'left' }}  >
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <img
                                                                src={folder} style={{ height: 30, width: 30, paddingTop: 5, marginLeft: 5 }} />
                                                            <p style={{ marginTop: -10, fontSize: 14, fontWeight: 'bold', marginLeft: 15 }}>Ticket No :</p>

                                                        </div>
                                                    </div>
                                                    <div style={{ textAlign: 'right' }}>
                                                        <p style={{ marginTop: -39, fontSize: 14, fontWeight: '600', marginLeft: 10, marginRight: '7%' }}>{moment(details.create_datetime).format('DD.MM.YYYY')}</p>


                                                    </div>
                                                    <div style={{ textAlign: 'right' }}>
                                                        <p style={{ marginTop: -2, textAlign: 'left', marginLeft: 50, fontSize: 14 }}>{details.ticket_number}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                    )
                                })}

                            <div className="add-ticket" onClick={addTicketHandler}  >
                                <img src={plus} style={{ height: 22, width: 30, marginTop: 20, textAlign: 'center' }} />
                                <p style={{ fontSize: 12, fontWeight: '600', marginTop: 20, width: 80, textAlign: 'left' }}>Add Ticket</p>

                            </div>
                        </div>
                    </>}
        </div>
    )
}

export default Tickets
