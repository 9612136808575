import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import '../App.css';
import { useNavigate, useLocation } from "react-router-dom";
import chi from '../assets/images/chi.jpeg';
import heading from '../assets/images/heading.png';

export default () => {

    const [phoneNo, setPhoneNo] = useState("");
    const [sendingOTP, setSendingOTP] = useState(false);
    const [buttonHide, setButtonHide] = useState(false);
    const [verifyButtonHide, setVerifyButtonHide] = useState(false);

    const [OTPbackendResponse, setOTPBackendResponse] = useState("");

    const [isEmptyPhoneNo, setIsEmptyPhoneNo] = useState(false);
    const [OTPsectionHide, setOTPSectionHide] = useState(false);
    const [OTPVerificationsectionHide, setOTPVerificationSectionHide] = useState(false);
    const [msg, setMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [patientDetails, setPatientDetails] = useState([]);
    const [medicaltrackingbt, setMedicaltrackingbt] = useState([]);
    const [isBtRecord, setIsBtRecord] = useState(false);
    const [medicaltrackingbs, setMedicaltrackingbs] = useState([]);
    const [isBsRecord, setIsBsRecord] = useState(false);
    const [medicaltrackingbp, setMedicaltrackingbp] = useState([]);
    const [medicaltrackinghr, setMedicaltrackinghr] = useState([]);
    const [isBpRecord, setIsBpRecord] = useState(false);
    const [isHrRecord, setIsHrRecord] = useState(false);
    const [OTPvalidated, setOTPValidated] = useState(true);
    const [yourOTP, setYourOTP] = useState("");
    const [isDisplayOTP, setIsDisplayOTP] = useState(false);

    //const [OTPinput, setOTPInput] = useState("");
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [lastThreeDigits, setLastThreeDigits] = useState("");
    const handleChange = (element, index) => {
        if (isNaN(element.value)) return false;

        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
        //setOtp(newOtp.join(""));

        //Focus next input
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };



    const navigate = useNavigate();
    const location = useLocation();

    const phoneNoChangeHandler = (e) => {
        setPhoneNo(e.target.value);
        setLastThreeDigits(e.target.value.substr(-3));
        console.log(lastThreeDigits);
    }

    // const OTPChangeHandler = (e) => {
    //     setOTPInput(e.target.value);
    // }

    useEffect(() => {
        let phoneno = localStorage.getItem("phoneNo");
        console.log(phoneno);
        if (phoneno != null) {
            navigate("/")
        }


    })
    const sendOTPHandler = () => {
        if (phoneNo != "") {
            setSendingOTP(true);
            setButtonHide(true);
            setIsEmptyPhoneNo(false);

            setMsg("");
            Axios.get(`${global.baseUrl}/api/patientapi/Patients/validate_phone?phone_number=${phoneNo}`)
                .then(response => {
                    console.log(response.data);
                    if (response.data.response != null) {
                        console.log(response.data.response.phone_number);
                        Axios.get(`${global.baseUrl}/api/commonapi/Misc/send_login_otp?phone=${response.data.response.phone_number}`)
                            .then(OTPresponse => {
                                setYourOTP(OTPresponse.data);
                                console.log('sendOTPHandler:otp response \n', OTPresponse.data);
                                setSendingOTP(false);
                                setButtonHide(false);
                                if (OTPresponse.data.OTP_SMS_RESPONSE == 'No') {
                                    setOTPBackendResponse(OTPresponse.data.OTP);
                                    setOTPSectionHide(true);
                                    setIsDisplayOTP(true);
                                }
                                else {
                                    setIsDisplayOTP(false);
                                    setOTPBackendResponse(OTPresponse.data.OTP);
                                    setOTPSectionHide(true);
                                    setIsDisplayOTP(false);
                                }

                            }).catch((err) => {
                                console.log('Reset Password: otp send failed', err);
                                setSendingOTP(false);
                                setButtonHide(false);

                            })

                    } else {
                        setSendingOTP(false);

                        setButtonHide(false);
                        setMsg("This phone number is not registered. Please sign up to use this app.")
                    }


                }).catch((err) => {
                    console.log('error', err);


                })

        } else {
            setIsEmptyPhoneNo(true);
            setButtonHide(false);

        }
    }
    let id;
    const verifyOTPHandler = () => {
        setVerifyButtonHide(true);
        setSendingOTP(true);
        const newOtp = otp.join("");
        if (OTPbackendResponse == newOtp) {
            console.log(phoneNo)
            localStorage.setItem('phoneNo', phoneNo);
            setSuccessMsg("Verified! MyMedical Records is showing");
            setMsg("");
            setVerifyButtonHide(true);
            setSendingOTP(false);
            setOTPVerificationSectionHide(true);
            navigate("/");




        } else {
            setMsg("OTP is not matching");
            setSuccessMsg("");
            setVerifyButtonHide(false);
            setSendingOTP(false);
        }
    }

    return (
        <>
            <div className="login" style={{ paddingTop: 10 }}
            // style={{ alignItems: 'center', height: 300, width: 'auto', marginTop: 60, marginLeft: 20, marginRight: 20 }}
            >


                <div style={{ backgroundColor: 'white', height: '75%' }}>
                    <img className="image" src={heading} style={{ height: 80, width: '100%' }} />
                    {OTPsectionHide == false ?
                        <>

                            <div>

                                <h3 style={{ textAlign: 'center', marginTop: 20, fontWeight: 'bold', fontSize: 20 }}>SIGN IN</h3>
                            </div>


                            <div style={{ textAlign: 'center' }}>
                                <input onChange={(event) => phoneNoChangeHandler(event)}
                                    style={{ border: '1px solid rgb(185, 184, 184)', backgroundColor: '#DDDDDD', color: 'black', width: '55%', height: 40, paddingLeft: 8, fontSize: 23, borderRadius: 8, marginTop: 50, textAlign: 'center', }} type="tel" placeholder=" phone number" maxLength={10} />
                            </div>

                            <div style={{ marginTop: 5, textAlign: 'center' }}>

                                {
                                    isEmptyPhoneNo ?
                                        <p style={{ fontSize: 16, fontWeight: 'bold', color: 'red' }}>Please enter phone no</p>
                                        :
                                        null
                                }
                                {msg ? <p style={{ fontSize: 16, fontWeight: 'bold', color: 'red', marginTop: 10 }}>{msg}</p> : null}


                                {
                                    buttonHide == false ?
                                        <button className="otp_button" onClick={sendOTPHandler} >SEND OTP</button>
                                        :
                                        null
                                }



                                {
                                    sendingOTP ?
                                        <p style={{ fontSize: 16, fontWeight: 'bold', color: '#6080eeed' }}>Sending...</p>
                                        :
                                        null
                                }

                            </div>
                            <div style={{ marginTop: 20, textAlign: 'center' }}>
                                <p>Don't have an account?&nbsp;<a style={{ textDecoration: 'none', fontSize: 15, fontWeight: 'bold' }} href="/SignUp">SignUp</a> </p>

                            </div>
                        </>
                        :
                        null


                    }

                    {
                        OTPsectionHide == true && OTPVerificationsectionHide == false ?
                            <div style={{ textAlign: 'center' }}>

                                {
                                    isDisplayOTP ?
                                        <p style={{ fontSize: 16, fontWeight: 'bold', color: '#6080eeed', marginTop: 20 }}>Your OTP IS : {OTPbackendResponse}</p>
                                        : <p style={{ fontSize: 16, fontWeight: 'bold', color: '#6080eeed', marginTop: 20 }}>Please enter otp received on your mobile *******{lastThreeDigits}</p>

                                }
                                <div>
                                    {/* <input onChange={(event) => OTPChangeHandler(event)}
                                        style={{ border: '1px solid rgb(185, 184, 184)', backgroundColor: '#DDDDDD', color: 'black', width: '60%', height: 30, paddingLeft: 8, fontSize: 12, borderRadius: 8, marginTop: 50, fontSize: 'small' }}
                                         type="number" placeholder="Enter your 6 digit OTP" /> */}
                                    {otp.map((data, index) => {
                                        return (
                                            <input
                                                className="otp-field"
                                                type="tel"
                                                name="otp"
                                                maxLength="1"
                                                key={index}
                                                value={data}
                                                style={{ height: 50, width: 40, marginLeft: 10, fontWeight: 'bold', fontSize: 20 }}
                                                onChange={(event) => handleChange(event.target, index)}
                                                onFocus={e => e.target.select()}
                                            />
                                        );
                                    })}
                                </div>
                                {
                                    verifyButtonHide == false ?
                                        <button className="otp_button" onClick={verifyOTPHandler} >VERIFY OTP</button>
                                        :
                                        null
                                }


                                {
                                    sendingOTP ?
                                        <p style={{ fontSize: 16, fontWeight: 'bold', color: '#6080eeed' }}>Verifing...</p>
                                        :
                                        null
                                }
                                {<p style={{ fontSize: 16, fontWeight: 'bold', color: 'red' }}>{msg}</p>}

                            </div>
                            : null
                    }
                </div>


            </div>
        </>

    )
}