import React, { useState, useEffect, useRef, useCallback } from 'react';
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import back from '../assets/images/back.png';
import { useRecoilState } from 'recoil';
import { patient_profile_image_show } from '../Store/PatientProfileDetails'
import { BiFolderPlus } from "react-icons/bi";
import imageCompression from 'browser-image-compression';
import Webcam from 'react-webcam';
import camera from '../assets/images/camera.png';
import cameraSwitch from '../assets/images/cameraSwitch.png';
import upload from '../assets/images/upload1.png';
import folder from '../assets/images/folder1.png';


export default () => {




    const [picture, setPicture] = useState(null);
    const [imgData, setImgData] = useState("");
    const [isSelectedImage, setIsSelectedImage] = useState(false);
    const [msg, setMsg] = useState("");
    const [buttonHide, setButtonHide] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [profileImgShow, setProfileImgShow] = useRecoilState(patient_profile_image_show)
    const [imageURL, setImageURL] = useState(null);
    const [myFile, setMyFile] = useState(null);
    const [selectFile, setSelectFile] = useState(false);
    const [file_type, setFile_type] = useState("");
    const [capturedImage, setCapturedImage] = useState("");
    const [isCapturePhotoHide, setIsCapturePhotoHide] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [base64ImageToFile, setBase64ImageToFile] = useState("");
    const [cameraOpen, setCameraOpen] = useState(false);
    let compressedFile;
    let url;
    console.log(location.state);

    const uploadPhotoHandler = (e) => {

        setButtonHide(true);
        e.preventDefault();
        let formdata = $(e.target);
        formdata = new FormData();
        formdata.append("file", imgData);
        formdata.append('patientid', location.state.up_id);

        $.ajax({
            type: "POST",
            url: `${global.baseUrl}/api/commonapi/FileUpload/insert`,
            data: formdata,
            processData: false,
            contentType: false,
            success(uploadedimage) {
                console.log(uploadedimage)
                if (uploadedimage) {
                    formdata.append("profile_image", uploadedimage);
                    formdata.append('patientid', location.state.up_id);
                    $.ajax({
                        type: "POST",
                        url: `${global.baseUrl}/api/patientapi/PatientBioProfile/save_patient_profile`,
                        data: formdata,
                        processData: false,
                        contentType: false,
                        success(data) {
                            console.log(data);
                            if (data) {
                                setButtonHide(false);
                                setMsg("Updated successfully");
                                setProfileImgShow(true);
                                setTimeout(() => {
                                    navigate("/");
                                }, 2000)


                            }

                        },
                        error: function (err) {
                            console.log(err);
                            setButtonHide(false);

                        }
                    });

                }

            },
            error: function (err) {
                console.log(err);
                setButtonHide(false);

            }
        });



    }
    const fileInputRef = useRef();
    const triggerUpload = () => {
        fileInputRef.current.click()
    }
    const fileChangeHandler = async (event) => {
        setSelectFile(true);
        console.log("fileChangeHandler: selectedfile  ", event.target.files[0])
        setFile_type(event.target.files[0].type);

        const options1 = {
            maxSizeMB: 0.2,
            maxWidthOrHeight: 1000,
            useWebWorker: true,
        };




        try {
            if (event.target.files[0].size > 512000) {
                compressedFile = await imageCompression(event.target.files[0], options1);
                console.log("compressed file ", compressedFile)
                const file = new File([compressedFile], `${compressedFile.name}.jpeg`, { type: "image/jpeg" })
                setImgData(file);
                url = URL.createObjectURL(compressedFile)
            }
            else {
                setImgData(event.target.files[0]);
                url = URL.createObjectURL(event.target.files[0])
            }



            console.log("url", url)
            setPicture(url)
            setIsSelectedImage(true);
        }
        catch (error) {
            console.log(error);
        }

    }
    const FACING_MODE_USER = "user";
    const FACING_MODE_ENVIRONMENT = "environment";

    const webcamRef = React.useRef(null);
    const videoConstraints = {
        facingMode: FACING_MODE_ENVIRONMENT
    };
    const [facingMode, setFacingMode] = useState(FACING_MODE_ENVIRONMENT);
    const startCam = () => {
        //setShowSelectFile(false);
        //setAddRecordButtonHide(true);
        console.log("camera is opening");
        setCameraOpen(true);
        setIsCapturePhotoHide(false);
        //setRefreshUploadedDoc(false);

    }
    const capturePhoto = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            // console.log("captured image ", imageSrc);
            setCapturedImage(imageSrc);
            setIsCapturePhotoHide(true);
            setErrMsg("");
            console.log("captured photo  ", imageSrc);
            const url = imageSrc;
            fetch(url)
                .then(res => res.blob())
                .then(blob => {
                    const file = new File([blob], "Filename.jpeg", { type: "image/jpeg" })
                    console.log(file);
                    setBase64ImageToFile(file);
                    setFile_type("image/jpeg");
                    setPicture(url)
                    setIsSelectedImage(true);
                    setCameraOpen(false);
                    setImgData(file);
                })


        },
        [webcamRef]
    );

    const handleClick = useCallback(() => {
        setFacingMode(
            prevState =>
                prevState === FACING_MODE_ENVIRONMENT
                    ? FACING_MODE_USER
                    : FACING_MODE_ENVIRONMENT
        );
    }, []);
    const backToRecordsHandler = () => {
        setCameraOpen(false);

    }

    return (

        <div className="page-user">

            <img src={back} style={{ height: 20, width: 20, marginTop: 20, marginLeft: 20 }} onClick={() => navigate("/EditProfile")} />

            <div style={{ marginTop: 5 }} className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4">



                        </div>
                        <div className="col-xl-4 col-lg-4">

                            <h6 className="card-title">Edit Photo</h6>

                        </div>
                        <div className="col-xl-4 col-lg-4">


                        </div>
                    </div>
                </div>
            </div>

            <div className="page-content">
                <div className="container"  >
                    <div style={{ marginTop: -10 }} className="row">

                        <div className="col-xl-12 col-lg-12">
                            {cameraOpen ?

                                <>
                                    <Webcam

                                        // videoConstraints={videoConstraints}
                                        style={{ marginTop: 20 }}
                                        //style={{ height: 475, width: 375 }}
                                        // screenshotFormat="image/jpeg"
                                        // audio={false}
                                        //width={400}
                                        ref={webcamRef}

                                        audio={false}
                                        screenshotFormat="image/jpeg"
                                        videoConstraints={{
                                            ...videoConstraints,
                                            facingMode
                                        }}
                                    />
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <button onClick={backToRecordsHandler} style={{ width: '18%' }} className="capture-photo-button" > <img src={back} style={{ height: 15, width: 15 }} /> Back </button>
                                        <button onClick={capturePhoto} className="capture-photo-button" style={{ width: '40%' }}> <img src={camera} style={{ height: 18, width: 18 }} />&nbsp;Capture Photo</button>

                                        <button onClick={handleClick} style={{ width: '35%' }} className="capture-photo-button" > <img src={cameraSwitch} style={{ height: 18, width: 18, paddingRight: 3 }} />Switch Camera</button>
                                    </div>


                                </> :

                                <div style={{ padding: 10, height: 550, backgroundColor: 'white', marginTop: 30 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <img style={{ height: 400, width: 600, marginLeft: 'auto', marginRight: 'auto', border: '0.1px solid blue' }} src={picture} />


                                    </div >
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div onClick={startCam} style={{ width: '30%' }}  >
                                            <img src={camera} style={{ height: 40, width: 40, marginTop: 15 }} />
                                            <p style={{ fontSize: 13, fontWeight: '600', marginTop: 16, }}>Camera</p>
                                        </div>
                                        <input className="fileinputstyle" style={{ marginTop: 15, width: '25%' }} type="file" name="mfile" id="mfile" ref={fileInputRef} onChange={(event) => fileChangeHandler(event)} />

                                        <img style={{ marginTop: 15, height: 39, marginLeft: -65, width: 40 }} onClick={triggerUpload} src={folder} />

                                        {isSelectedImage ?
                                            <>
                                                <div style={{ textAlign: 'left', marginLeft: 85, marginTop: -60 }}>
                                                    <form
                                                        method="post"

                                                    >
                                                        {buttonHide == false ?
                                                            <div style={{ cursor: 'pointer' }} onClick={(event) => uploadPhotoHandler(event)} >
                                                                <img src={upload} style={{ marginTop: 75, height: 45, width: 45, cursor: 'pointer' }} />
                                                                <p style={{ fontSize: 13, fontWeight: '600', marginTop: 10, marginLeft: 3 }}>Upload</p>
                                                            </div>

                                                            :
                                                            <p style={{ color: 'green', fontSize: 13, marginTop: 75 }}>Uploading...</p>
                                                        }
                                                    </form>
                                                </div>

                                            </>
                                            :
                                            null


                                        }

                                    </div>
                                    <p style={{ fontSize: 13, fontWeight: '600', marginTop: -22, marginLeft: -62 }}>My files</p>
                                    {msg ? <p style={{ color: 'green', fontSize: 13, fontWeight: 'bold', marginTop: 25 }}>{msg}</p> : null}
                                </div>
                            }


                        </div>
                    </div>
                </div>
            </div>





        </div>

    )
}